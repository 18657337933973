import React, { lazy, Suspense } from 'react'
import { Redirect } from 'react-router-dom'
import Home from '../page/Home'

const SuspenseComponent = (Component) => (props) => {
  console.log(props.history.action)
  return (
    <Suspense fallback={null}>
      <Component {...props}></Component>
    </Suspense>
  )
}

// const MainComponent = lazy(() => import('../page/Main'))
const ExamComponent = lazy(() => import('../page/Exam'))
const ExamQuestionComponent = lazy(() => import('../page/ExamQuestion'))
const CourseComponent = lazy(() => import('../page/Course'))
const CourseDetailComponent = lazy(() => import('../page/CourseDetail'))
const UserCourseComponent = lazy(() => import('../page/UserCourse'))
const UserExamComponent = lazy(() => import('../page/UserExam'))
const UserExamAnswerComponent = lazy(() => import('../page/ExamAnswer'))
const UserExamResultComponent = lazy(() => import('../page/ExamResult'))
const UserNewsdetailComponent = lazy(() => import('../page/NewsDetail'))
const CourseGroupComponent = lazy(() => import('../page/CourseGroup'))
const CourseGroupDetailListComponent = lazy(() =>
  import('../page/CourseGroupDetailList')
)
const AchievementComponent = lazy(() => import('../page/Achievement'))
const UserCenterComponent = lazy(() => import('../page/UserCenter'))
const PreviewFileComponent = lazy(() => import('../page/PreviewFile'))
const HelpComponent = lazy(() => import('../page/Help'))
const MytaskComponent = lazy(() => import('../page/Mytask'))
const SearchComponent = lazy(() => import('../page/Search'))
const PutquestionComponent= lazy(() => import('../page/Putquestions'))
const AskquestionComponent = lazy(() => import('../page/Askquestion'))
const CoursetypeComponent = lazy(()=> import('../page/CoursetypeList'))

const MainComponent = lazy(() => import('../page/Sdsp/Home/index.jsx'))
const DiseaseMenu = lazy(() => import('../page/Sdsp/Home/menu.jsx'))
const Sqsj = lazy(() => import('../page/Sdsp/Sqsj/index.jsx'))
const Dcl = lazy(() => import('../page/Sdsp/Dcl/index.jsx'))
const Djs = lazy(() => import('../page/Sdsp/Djs/index.jsx'))
const Jyjd = lazy(() => import('../page/Sdsp/Jyjd/index.jsx'))
const Ycl = lazy(() => import('../page/Sdsp/Ycl/index.jsx'))
const Yth = lazy(() => import('../page/Sdsp/Yth/index.jsx'))
const DataDetail = lazy(() => import('../page/Sdsp/dataDetail/index.jsx'))
const Imgdetail = lazy(()=> import('../page/Sdsp/dataDetail/imgdetail/index.jsx'))

export default [
  {
    path: '/',
    component: Home,
    routes: [
      {
        path: '/',
        exact: true,
        render: () => <Redirect to={'/main'} />,
      },
      {
        path: '/main',
        exact: true,
        component: SuspenseComponent(MainComponent),
      },
      {
        path: '/main/diseaseMenu',
        exact: true,
        component: SuspenseComponent(DiseaseMenu),
      },
      {
        path: '/main/sqsj',
        exact: true,
        component: SuspenseComponent(Sqsj),
      },
      {
        path: '/main/dcl',
        exact: true,
        component: SuspenseComponent(Dcl),
      },
      {
        path: '/main/djs',
        exact: true,
        component: SuspenseComponent(Djs),
      },
      {
        path: '/main/jyjd',
        exact: true,
        component: SuspenseComponent(Jyjd),
      },
      {
        path: '/main/ycl',
        exact: true,
        component: SuspenseComponent(Ycl),
      },
      {
        path: '/main/yth',
        exact: true,
        component: SuspenseComponent(Yth),
      },
      {
        path: '/main/detail',
        exact: true,
        component: SuspenseComponent(DataDetail),
      },
      {
        path:"/main/detail/imgdetail",
        exact: true,
        component: SuspenseComponent(Imgdetail),
      }
    ]
  }
]