/* import React, { useState, useContext, useEffect } from 'react'; */
import Cookie from 'js-cookie'
import { AUTH_TOKEN_NAME } from '@/constant'
/* const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const token = getAuthToken();
  const [logged, setLogged] = useState(token ? true : false);

  useEffect(() => {
    if (!logged) {
      removeAuthToken();
    }
  }, [logged]);

  return <AuthContext.Provider value={{ logged, setLogged }}>{children}</AuthContext.Provider>
}

export const authUser = () => {
  return useContext(AuthContext);
} */

/**
 * 设置token
 * @param {*} token
 */
export const setAuthToken = (token, fn) => {
  //cookie设置2天
  // removeAuthToken()
    sessionStorage.setItem('tk',token)
  // if(!Cookie.get(AUTH_TOKEN_NAME)){
  //   Cookie.set(AUTH_TOKEN_NAME, token, {
  //     expires: new Date(new Date().getTime() + 2 * 24 * 60 * 60 * 1000),
  //   })
  // }
  
  // let timer = window.setInterval(() => {
  //   if (getAuthToken()) {
  //     window.clearInterval(timer)
  //     if (fn) {
  //       fn()
  //     }
  //   }
  // }, 100)
}

/**
 * 获取认证token
 * @param {*} options
 */
export const getAuthToken = (e) => {
  const token = sessionStorage.getItem('tk')
  if(!token){
    return null
  }
  return `${token}`
  // const token = Cookie.get(AUTH_TOKEN_NAME) || null
  // if (!token){
  //   return null
  // } 
  // return `${token}`
}

/**
 * 删除认证token
 */
export const removeAuthToken = () => {
  console.log('removeAuthToken')
  if (!getAuthToken()) {
    return false
  }

  Cookie.remove(AUTH_TOKEN_NAME)
  localStorage.removeItem(`persist:${AUTH_TOKEN_NAME}`)

  return true
}
