const setupWebViewJavascriptBridge = (callback) => {
  if (window.WebViewJavascriptBridge) {
    return callback(window.WebViewJavascriptBridge)
  }
  if (window.WVJBCallbacks) {
    return window.WVJBCallbacks.push(callback)
  }
  window.WVJBCallbacks = [callback]
  var WVJBIframe = document.createElement('iframe')
  WVJBIframe.style.display = 'none'
  // WVJBIframe.src = 'https://__bridge_loaded__'
  
  WVJBIframe.src = 'wvjbscheme://__BRIDGE_LOADED__'
  document.documentElement.appendChild(WVJBIframe)
  setTimeout(function () {
    document.documentElement.removeChild(WVJBIframe)
  }, 0)
}

const AppUser = async () => {
  var u = navigator.userAgent
  if (u.indexOf('Android') > -1 || u.indexOf('Adr') > -1) {
    return await AndroidUser()
  } else if (!!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
    return await IOSUser()
  }
}

const AndroidUser = async () => {
  return await new Promise((resolve, reject) => {
    setTimeout(function () {
      //global，window获取不到 安卓映射的对象
      let doctorId = global.umerSdSp.funJsToApp(
        JSON.stringify({
          jsonContent: '',
          operate: 'doctorId',
        }),
        'doctorId'
      ) 
      sessionStorage.setItem('Id',doctorId)
      return resolve(doctorId)
    }, 100)
  })
}

const IOSUser = async() => {
  console.log('进入ios')
  return await new Promise((resolve, reject) => {
    console.log('进入Promise')
    setupWebViewJavascriptBridge(function (bridge) {
      console.log(bridge)
      console.log('进入setupWebViewJavascriptBridge')
      bridge.callHandler(
        'funJsToApp',
        {
          operate: 'doctorId',
        },
        function (response) {
          console.log('doctorId',response)
          sessionStorage.setItem('Id',response)
            return resolve(response)
        }
      )
    })
  })
}

export { AppUser }
