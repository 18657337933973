import { axiosInstance } from './config'

export const getToken = (doctorId) => {
  return axiosInstance.post(`api/user/autoLogin?doctorId=${doctorId}`)
}

export const getNavBars = () => {
  return axiosInstance.post(`api/navibar/getNavibars.do?enpId=1&appVersion=100`)
}

export const getCourseCategory = () => {
  return axiosInstance.post(`api/course/getCourseClassifications`)
}

export const getCourses = (categoryId, pageNum, pageSize) => {
  return axiosInstance.post(
    `api/course/getCoursesByCourseClassificationId?courseClassificationId=${categoryId}&pageNum=${pageNum}&pageSize=${pageSize}`
  )
}

export const getCourse = (courseId) => {
  return axiosInstance.post(`api/course/getCoursesById?courseId=${courseId}`)
}

export const getCourseQuestion = (courseId) => {
  return axiosInstance.post(
    `api/course/getQuestionsByCourseId?courseId=${courseId}`
  )
}

export const getCourseMessage = (courseId, pageNum, pageSize) => {
  return axiosInstance.post(
    `api/course/getCourseLeaveMessages?courseId=${courseId}&pageNum=${pageNum}&pageSize=${pageSize}`
  )
}

export const updateCourseLearnRecord = (
  userLearnRecordId,
  userLearnRecordDetilId,
  learnDuration,
  viewTime
) => {
  return axiosInstance.post(
    `api/course/updateUserLearnRecord?userLearnRecordId=${userLearnRecordId}&userLearnRecordDetilId=${userLearnRecordDetilId}&learnDuration=${learnDuration}&viewTime=${viewTime}`
  )
}
// 考试
export const getExams = (token, pageNum, pageSize) => {
  return axiosInstance.post(
    `api/examPaper/getExamPapers?pageNum=${pageNum}&pageSize=${pageSize}&token=${token}`
  )
}
//我的补考
export const AnswerExamPapers = (token, pageNum, pageSize) => {
  return axiosInstance.post(
    `/api/examPaper/getAnswerExamPapers?pageNum=${pageNum}&pageSize=${pageSize}&token=${token}`
  )
}
export const getQuestion = (examPaperId, courseResourseType, courseId, courseGroupId, examDuration) => {
  console.log(examPaperId, courseResourseType, courseId, courseGroupId, examDuration)
  return axiosInstance.post(
    `/api/examPaper/getExamPaper?examPaperId=${examPaperId}&courseResourseType=${courseResourseType}&courseId=${courseId}&courseGroupId=${courseGroupId}&examDuration=${examDuration}`
  )
}

export const submitAnswers = (token, examPaperJson) => {
  return axiosInstance.post(
    `/api/examPaper/saveExamPaper`,
    examPaperJson,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
}
export const getAnswerExamPaperById = (answerId) => {
  return axiosInstance.post(
    `/api/examPaper/getAnswerExamPaperById?answerExamPaperId=${answerId}`
  )
}
export const getNewsList = (newsType, pageNum, pageSize) => {
  return axiosInstance.post(
    `/api/news/listAllNewsByType?newsType=${newsType}&pageNum=${pageNum}&pageSize=${pageSize}`
  )
}

export const getNewsDetail = (id) => {
  return axiosInstance.post(
    `/api/news/getNewsById?id=${id}`
  )
}

export const getCollectCourses = (pageNum, pageSize) => {
  return axiosInstance.post(
    `/api/course/getCollectCourses?pageNum=${pageNum}&pageSize=${pageSize}`
  )
}

export const getMastLearn = (pageNum, pageSize) => {
  return axiosInstance.post(
    `/api/task/getMastLearn?pageNum=${pageNum}&pageSize=${pageSize}`
  )
}

export const getCourseGroupClassifications = () => {
  return axiosInstance.post(
    `/api/course/getCourseGroupClassifications`
  )
}

export const getCoursesGroupByCourseGroupClassificationId = (courseGroupClassificationId, pageNum, pageSize) => {
  return axiosInstance.post(
    `/api/course/getCoursesGroupByCourseGroupClassificationId?pageNum=${pageNum}&pageSize=${pageSize}&courseGroupClassificationId=${courseGroupClassificationId}`
  )
}
export const getCourseGroupDetilByCourseGroupId = (courseGroupId, pageNum, pageSize) => {
  return axiosInstance.post(
    `/api/course/getCourseGroupDetilByCourseGroupId?pageNum=${pageNum}&pageSize=${pageSize}&courseGroupId=${courseGroupId}`
  )
}

export const getUserRank = (courseResourseType, pageNum, pageSize) => {
  return axiosInstance.post(
    `/api/examPaper/getUserRank?pageNum=${pageNum}&pageSize=${pageSize}&courseResourseType=${courseResourseType}`
  )
}

export const getUserLearnRecords = (pageNum, pageSize) => {
  return axiosInstance.post(
    `/api/course/getUserLearnRecords?pageNum=${pageNum}&pageSize=${pageSize}`
  )
}
export const getUserLearnRecordHeard = (today) => {
  return axiosInstance.post(
    `/api/course/getUserLearnRecordHeard?today=${today}`
  )
}

//用户收藏课程课程组
export const collectCourse = (id, type) => {
  return axiosInstance.post(
    `/api/course/collect?type=${type}&id=${id}`
  )
}
//取消收藏
export const uncollectCourse = (id, type) => {
  return axiosInstance.post(
    `/api/course/uncollect?type=${type}&id=${id}`
  )
}

//关键字获取
export const getkeywordData = (keyword, pageNum, pageSize) => {
  return axiosInstance.post(
    `/api/course/getCoursesByKeyword?keyword=${keyword}&pageNum=${pageNum}&pageSize=${pageSize}`
  )
}

//关键字获取组
export const getgroupkeywordData = (keyword, pageNum, pageSize) => {
  return axiosInstance.post(
    `/api/course/getCoursesGroupByKeyword?keyword=${keyword}&pageNum=${pageNum}&pageSize=${pageSize}`
  )
}

export const getAsksByType = (type, pageNum, pageSize) => {
  return axiosInstance.post(
    `/api/ask/getAsksByType?type=${type}&pageNum=${pageNum}&pageSize=${pageSize}`
  )
}

export const submitQuestion = (content, picJson) => {
  return axiosInstance.post(
    `/api/ask/createAsk?content=${content}`
  )
}

// 获取当前登陆陆用户任务提醒
export const getUserTaskRemind = () => {
  return axiosInstance.post(
    `http://192.168.0.4:8089/oedu/api/task/getUserTaskRemind`
  )
}


























export const GetMainMenu = (content, picJson) => {
  return axiosInstance.post(
    `/api/inspection/getMenuByDoctorId?dockerNumber=${content}`
  )
}





export const GetSjxm = (content, diseaseType, picJson) => {
  return axiosInstance.post(
    `/api/inspection/getInspectionFormInfo?doctorNumber=${content}&diseaseType=${diseaseType}`
  )
}


export const Insertdata = (content) => {
  return axiosInstance.post(
    `/api/inspection/insertInspection?patientName=${content.postdata}&cardNo=${content.cardNo}&gender=${content.gender}&birthDay=${content.birthDay}&address=${content.address}&phone=${content.phone}&lczd=${content.lczd}&lcbs=${content.lcbs}&pstp=${content.pstp}&sjxmId=${content.sjxmId}&bblxId=${content.bblxId}&bbwz=${content.bbwz}&gdsj=${content.gdsj}&qcsj=${content.qcsj}&bbms=${content.bbms}&sjdw=${content.sjdw}&sjks=${content.sjks}&sjys=${content.sjys}&yslxfs=${content.yslxfs}&sjrq=${content.sjrq}&jsysxm=${content.jsysxm}&jsysphone=${content.jsysphone}&jsysaddress=${content.jsysaddress}&id=${content.jsysaddress}`
  )
}

export const CreateCode = (content) =>{
  return axiosInstance.post(
    `/api/inspection/createCode?inspectionId=${content}`
  )
}


export const buycallback = (content) =>{
  return axiosInstance.post(
    `/api/inspection/queryOrderPayStatus?outTradeNo=${content}`
  )
}

export const getBllist = (doctorNumber,status,sjxmid,starttime,endtime,xm) =>{
  return axiosInstance.post(
    `/api/inspection/getInspectionList?doctorNumber=${doctorNumber}&status=${status}&sjxmId=${sjxmid}&startTime=${starttime}&endTime=${endtime}&patientName=${xm}`
  )
}


export const getDataDetail = (inspectionId) =>{
  return axiosInstance.post(
    `/api/inspection/getInspectionDetail?inspectionId=${inspectionId}`
  )
}
export const jieshouData = (handleKey,inspectionId,doctorNumber,returnReason) =>{
  return axiosInstance.post(
    `/api/inspection/handleInspection?handleKey=${handleKey}&inspectionId=${inspectionId}&returnReason=${returnReason}&doctorNumber=${doctorNumber}`
  )
}
export const putthlyData = (thly,inspectionId,doctorNumber) =>{
  return axiosInstance.post(
    `/api/inspection/editRefund?thly=${thly}&inspectionId=${inspectionId}&doctorNumber=${doctorNumber}`
  )
}
export const putorupdateImg = (files,inspectionId) =>{
  return axiosInstance.post(
    `/api/inspection/handlePending?files=${files}&inspectionId=${inspectionId}`
  )
}


export const getjinduID = (doctorId) =>{
  return axiosInstance.post(
    `/api/inspection/getInspectionProgress?doctorId=${doctorId}`
  )
}

export const getXmDctID = (data) =>{
  return axiosInstance.post(
    `/api/inspection/getDoctorByProjectId?projectId=${data}`
  )
}
