import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import { renderRoutes } from 'react-router-config'
import { HashRouter } from 'react-router-dom'
import routes from './routes'
import store from './store'
import { getToken } from './api/http'
import { setAuthToken, getAuthToken } from './utils/auth'
import { AppUser } from './utils/appBridge'
import VConsole from 'vconsole'
import { useState } from 'react'

// new VConsole() // 初始化

function App() {
  useEffect(() => {
    async function fetchToken() {
      // let doctorId = await AppUser()
      // console.log('doctorId:', doctorId)
      // sessionStorage.setItem('Id',doctorId)
        // let token = await getToken(doctorId)
        // window.location.reload();
        // setAuthToken(token)
      // console.log('getAuthToken',getAuthToken())
    }
    fetchToken()

  }, [])
  return (
    <Provider store={store}>
      <HashRouter>{renderRoutes(routes)}</HashRouter>
    </Provider>
  )
}

export default App
