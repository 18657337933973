import React, { Fragment } from 'react'
import { renderRoutes } from 'react-router-config'
// import TabBars from '@/container/TabBars'

function Home(props) {
  const renderContent = () => {
    const { route } = props
    return renderRoutes(route.routes)
  }

  return (
    <Fragment>
      <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
        <div style={{ marginBottom: '50' }}>{renderContent()}</div>
        <div style={{ position: 'fixed', bottom: 0, left: 0, width: '100%',zIndex:'20' }}>
          {/* <TabBars {...props} /> */}
        </div>
      </div>
    </Fragment>
  )
}

export default React.memo(Home)
